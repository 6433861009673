import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";

const StyledAppBar = styled(AppBar)(({ theme, scrolled }) => ({
  background: scrolled ? "#fff" : "transparent",
  boxShadow: scrolled ? theme.shadows[4] : "none",
  transition: "background-color 0.3s, box-shadow 0.3s",
  position: "fixed",
  width: "100%",
}));

const NavLinks = styled(Stack)(({ theme, scrolled }) => ({
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  "& a": {
    marginLeft: theme.spacing(3),
    textDecoration: "none",
  },
}));

const SocialIcons = styled(Stack)(({ theme, scrolled }) => ({
  flexDirection: "row",
  alignItems: "center",
  "& a": {
    color: scrolled ? "#3C3441" : "#3C3441",
    marginLeft: theme.spacing(2),
  },
}));

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > window.innerHeight) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <StyledAppBar scrolled={scrolled}>
      <Toolbar>
        <Box flexGrow={1}>
          <Typography variant="h1">Anaïs Vincent</Typography>
        </Box>
        <NavLinks scrolled={scrolled}>
          <Link href="#parcours">
            <Typography variant="h3">Mon parcours</Typography>
          </Link>
          <Link href="#galery">
            <Typography variant="h3">Galerie</Typography>
          </Link>
          <Link href="#goals">
            {" "}
            <Typography variant="h3">Mes objectifs</Typography>
          </Link>
          <Link href="#partners">
            <Typography variant="h3">Partenaires</Typography>
          </Link>
          <Link href="#contact">
            <Typography variant="h3">Me contacter</Typography>
          </Link>
        </NavLinks>
        <SocialIcons scrolled={scrolled}>
          <Link
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </Link>
          <Link
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </Link>
        </SocialIcons>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
