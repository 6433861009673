import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVER_URL;
console.log("API URL:", { apiUrl });

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error?.response?.data?.message || error.message);
  }
);

export default api;
