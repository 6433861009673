import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Container,
  Stack,
  useTheme,
} from "@mui/material";
import bioImage from "../img/BIO.png";
import miniGalerie from "../img/MINI-GALERIE.png";
import imagPalmares from "../img/imgPalmares.png";
import mini1 from "../img/miniG.JPG";
import mini2 from "../img/miniG2.JPG";
import mini3 from "../img/miniG3.JPG";
import mini4 from "../img/miniG4.JPG";
const ParcoursSection = () => {
  const theme = useTheme();
  return (
    <Container sx={{ marginTop: 7 }}>
      <Stack direction="column" spacing={4}>
        <Card sx={{ padding: 4, boxShadow: "none" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                image={bioImage}
                alt="Anaïs Vincent"
                sx={{
                  borderRadius: 2,
                  width: "100%",
                }}
              ></CardMedia>{" "}
            </Grid>
            <Grid item xs={12} md={8}>
              <CardContent>
                <Typography variant="h4">Mon parcours</Typography>
                <Typography variant="h2">Qui suis-je ?</Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Je m'appelle Anaïs, j'ai 24 ans et je suis originaire de la
                  Drôme, dans les pré-Alpes françaises.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Passionnée de sport, j'ai depuis mon plus jeune âge pratiqué
                  plusieurs activités physiques et sportives de pleine nature
                  comme la course à pied, l'escalade, la slackline, ou encore le
                  ski de rando. Toute l'année, je m'adonnais donc à mes passions
                  suivant les saisons, jusqu'à ce jour du 06 mars 2021 où
                  victime d'une lourde chute en falaise, je suis devenue
                  paraplégique. Après une longue période de rééducation,
                  d'efforts et d'apprentissages, c'est tout naturellement que je
                  me suis rapprochée de la pratique en Handisport et plus
                  particulièrement du paracyclisme.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  J'ai très rapidement été prise par le coup de pédale pour
                  parcourir les routes et campagnes Drômoises. De belles
                  rencontres comme par exemple, Florian Jouanny m'ont permises
                  d'aller plus loin dans l'entraînement et de participer à mes
                  premières compétitions. Depuis je n'ai jamais cessé de pédaler
                  et aujourd'hui ma route se trace vers les Jeux Paralympiques
                  de Paris 2024...
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini1}
                alt=""
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini2}
                alt=" "
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini3}
                alt=" "
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia
                component="img"
                image={mini4}
                alt=""
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ padding: { xs: 1, md: 4 } }}>
          <Card
            sx={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: 2,
              boxShadow: "none",
            }}
          >
            <Grid container spacing={4} padding={4}>
              <Grid item xs={12} md={8}>
                <CardContent>
                  <Typography
                    variant="h2"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <span
                      role="img"
                      aria-label="trophy"
                      style={{ marginRight: "0.5rem" }}
                    >
                      🏆
                    </span>
                    Mon palmarès
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <ul>
                      <li>
                        <strong>
                          Double médaillée aux Championnats du Monde 2023
                        </strong>
                        <br />- Glasgow (Écosse) - en course contre la montre et
                        course en ligne
                      </li>
                      <li>
                        <strong>Championne d’Europe 2023</strong> <br /> -
                        Rotterdam (Pays Bas) - en relais par équipe
                      </li>
                      <li>
                        <strong>
                          Médaillée de bronze aux Championnats d’Europe 2023
                        </strong>
                        <br />- Rotterdam (Pays Bas) - en course contre la
                        montre
                      </li>
                      <li>
                        <strong>Double championne de France 2023 / 2024</strong>{" "}
                        <br /> - en course contre la montre et course en ligne
                      </li>
                      <li>
                        <strong>
                          Double médaillée d’argent à la Coupe du Monde 2024
                        </strong>
                        <br />- Ostende (Belgique) - en course contre la montre
                        et relais par équipe
                      </li>
                      <li>
                        <strong>Médaillée d’or à la Coupe du Monde 2024</strong>
                        <br />- Maniago (Italie) - en course contre la montre
                      </li>
                    </ul>
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={imagPalmares}
                  alt="Anaïs Vincent"
                  sx={{
                    borderRadius: 2,
                    width: {
                      xs: "100%", // Width is 100% on extra small screens
                      md: "100%", // Width is 100% from medium screens and up
                    },
                    height: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Container>
  );
};

export default ParcoursSection;
