import React from "react";
import { Box, Typography, Grid, Link } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#3C3441", color: "white", padding: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md="auto">
          <Typography
            variant="body2"
            sx={{ display: "inline", marginRight: 1 }}
          >
            Site réalisé par
          </Typography>
          <Link
            href="https://arkone.fr"
            color="inherit"
            sx={{ fontWeight: "bold", marginRight: 1 }}
          >
            Arkone
          </Link>
          <Typography
            variant="body2"
            sx={{ display: "inline", marginRight: 1 }}
          >
            -
          </Typography>
          <Link
            href="/mentions-legales"
            color="inherit"
            sx={{ fontWeight: "bold" }}
          >
            Mentions légales
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ textAlign: { xs: "center", md: "right" } }}
        >
          <Link
            href="https://www.instagram.com/anais.vinct/"
            target="_blank"
            rel="noopener"
            color="inherit"
            sx={{ marginRight: 1 }}
          >
            <InstagramIcon />
          </Link>
          <Link
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <LinkedInIcon />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
