import React from "react";
import Header from "./Composants/Header";
import imgHeader from "./img/photo-header.png";
// Importer les polices dans index.js ou App.js
import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/alumni-sans"; // Defaults to weight 400
import theme from "./theme";
import { Typography, Box } from "@mui/material";
import ParcoursSection from "./Composants/Parcours";
import PhotoGallery from "./Composants/Galerie";
import ObjectivesSection from "./Composants/Objectifs";
import PartnerGallery from "./Composants/Partners";
import ContactSection from "./Composants/ContactForm";
import Footer from "./Composants/Footer";

function App() {
  return (
    <div>
      <Header />
      <section
        id="home"
        style={{
          height: "100vh",
          backgroundImage: `url(${imgHeader})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center", // Centraliser le texte à l'intérieur de Box
          color: "white",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Alumni Sans",
              fontSize: { xs: "48px", sm: "60px", md: "80px", lg: "100px" }, // Responsive font size
              fontWeight: 600,
              textTransform: "capitalize",
              color: "white",
            }}
          >
            Anaïs Vincent
          </Typography>
          <Typography
            sx={{
              fontFamily: "Alumni Sans",
              fontSize: { xs: "24px", sm: "30px", md: "36px", lg: "46px" }, // Responsive font size
              fontWeight: 400,
              textTransform: "capitalize",
              color: "white",
            }}
          >
            Paracyclisme
          </Typography>
          <Typography
            sx={{
              fontFamily: "Alumni Sans",
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "24px" }, // Responsive font size
              fontWeight: 600,
              textTransform: "capitalize",
              color: "white",
              mt: 2, // Margin top
            }}
          >
            “Face à la roche, le ruisseau l’emporte toujours, non pas par la
            force mais par la persévérance.” H. Jackson Brown
          </Typography>
        </Box>
      </section>
      <section id="parcours">
        {" "}
        <ParcoursSection />
      </section>
      <section id="galery">
        <PhotoGallery />
      </section>
      <section id="goals">
        <ObjectivesSection />
      </section>
      <section id="partners">
        <PartnerGallery />
      </section>
      <section id="contact">
        <ContactSection />
      </section>
      <Footer />
    </div>
  );
}

export default App;
