import React from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  Stack,
  Container,
  Typography,
} from "@mui/material";

// Replace these imports with the actual paths to your images
import Image1 from "../img/IMG_8967-min.JPG"; // Replace with the correct path to your image
import Image2 from "../img/champs.JPG"; // Replace with the correct path to your image
import Image3 from "../img/velo-haut.JPG"; // Replace with the correct path to your image
import Image4 from "../img/IMG_4815-min.JPG"; // Replace with the correct path to your image
import Image5 from "../img/Handi Bike Anaïs Vincent ©REY_7435-min.JPEG"; // Replace with the correct path to your image
import Image6 from "../img/folriant.JPG"; // Replace with the correct path to your image
import Image7 from "../img/IMG_4852-min.JPG"; // Replace with the correct path to your image
import Image8 from "../img/velo-feuille.JPG"; // Replace with the correct path to your image
import Image9 from "../img/Anaïs Vincent Handi Bike ©REY_7380-min.JPEG"; // Replace with the correct path to your image

const PhotoGallery = () => {
  return (
    <Container sx={{ marginTop: 7, padding: 4 }}>
      <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 4 }}>
        Galerie
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image1}
                  alt="Image 1"
                  sx={{ height: 200 }}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image2}
                  alt="Image 2"
                  sx={{ height: 200 }}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image4}
                  alt="Image 4"
                  sx={{ height: 350 }}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image5}
                  alt="Image 5"
                  sx={{ height: 350 }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: "none" }}>
            <CardMedia
              component="img"
              image={Image3}
              alt="Image 3"
              sx={{ height: 550 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: "none" }}>
            <CardMedia
              component="img"
              image={Image6}
              alt="Image 6"
              sx={{ height: 400 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image7}
                  alt="Image 7"
                  sx={{ height: 200 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  image={Image8}
                  alt="Image 8"
                  sx={{ height: 200 }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: "none" }}>
            <CardMedia
              component="img"
              image={Image9}
              alt="Image 9"
              sx={{ height: 400 }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PhotoGallery;
