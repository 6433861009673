import React from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  Stack,
  Container,
  Typography,
} from "@mui/material";

// Replace these imports with the actual paths to your images
import LaDrome from "../img/ladrome.jpg";
import GrandesRousses from "../img/grandes rousses.jpg";
import Hardis from "../img/hardis.jpg";
import PermoBile from "../img/permobile.jpg";

const PartnerGallery = () => {
  return (
    <Container sx={{ marginTop: 7, padding: 4 }}>
      <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 4 }}>
        Mes partenaires
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={3}>
          <Card
            sx={{
              boxShadow: "none",
            }}>
            <CardMedia
              component="img"
              image={LaDrome}
              alt="La drome"
              sx={{ height: 200 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card
            sx={{
              boxShadow: "none",
            }}>
            <CardMedia
              component="img"
              image={GrandesRousses}
              alt="GrandesRousses hotel"
              sx={{ height: 200 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card
            sx={{
              boxShadow: "none",
            }}>
            <CardMedia
              component="img"
              image={Hardis}
              alt="Hardis groupe"
              sx={{ height: 200 }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{ boxShadow: "none" }}>
            <CardMedia
              component="img"
              image={PermoBile}
              alt="PermoBile"
              sx={{ height: 200 }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnerGallery;
