import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans";
import "@fontsource/alumni-sans";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3C3441",
      light: "#F3EDF2",
    },
    secondary: {
      main: "#5A6039",
    },
  },
  typography: {
    fontFamily: ["Alumni Sans", "Open Sans", "sans-serif"].join(","),
    h1: {
      fontFamily: "Alumni Sans",
      fontSize: 46,
      fontWeight: 600,
      color: "#3C3441",
    },
    h2: {
      fontFamily: "Alumni Sans",
      fontSize: 40,
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#3C3441",
    },
    h3: {
      fontFamily: "Alumni Sans",
      fontSize: 24,
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#3C3441",
    },
    h4: {
      fontFamily: "Alumni Sans",
      fontSize: 24,
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#5A6039",
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 400,

      color: "#3C3441",
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 700,

      color: "#3C3441",
    },
  },
});

export default theme;
